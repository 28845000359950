.App-body {
  display: grid;
 
  grid-template-columns: 20em auto;
 
}




.App-link {
  color: #09d3ac;
}
@media only screen and (max-width: 500px) {


  .App-body {
    display: grid;
   
    grid-template-columns:  auto;
   
  }
}